import React, { HTMLProps } from "react";
import { top } from './Layout.module.scss'
import Search from "@/components/search/Search";
import Button from "@/components/button/Button";
import logo from '@/static/images/ibb.svg'
import { Link } from "gatsby";

interface LayoutProps extends HTMLProps<HTMLElement> {}

export default function Layout({children}: LayoutProps) {
  return (
    <div className={`bg-white ${top}`}>
      <div className="fixed z-10 top-0 right-0 left-0 flex flex-row items-center px-8 bg-white h-[5vw] min-h-[70px] max-h-[90px]">
        <Link
          to="/"
          className="h-full min-w-[50px] w-[10vw] max-w-[70px] mr-8"
          style={{
            background: `url(${logo}) left center / contain no-repeat`,
          }}
        />
        <Search />
        <div className="ml-auto inline-flex flex-row items-center space-x-8">
          <Link
            to="/login"
            className="text-ibb hover:text-ibb-accent transition-colors"
          >
            Login
          </Link>
          <Button type="primary">
            Registrieren
          </Button>
        </div>
      </div>

      <div className="content">
        {children}
      </div>
    </div>
  )
}
