import React from 'react'

import {useEffect, useRef, useState} from 'react'
import {useHotkeys} from 'react-hotkeys-hook'

export default function Search() {
  const input = useRef<HTMLInputElement|null>()
  const [showResults, setShowResults] = useState<boolean>(false)
  const [isMac, setIsMac] = useState<boolean>(false)

  useEffect(() => {
    setIsMac(navigator.platform.toUpperCase().indexOf('MAC') >= 0)
  }, [])

  const handleHotkey = (event: any) => {
    event.preventDefault()

    setShowResults(true)
    input.current?.focus()
    input.current?.select()
  }

  useHotkeys('cmd+k', handleHotkey)
  useHotkeys('ctrl+k', handleHotkey)

  useHotkeys('escape', () => {
    setShowResults(false)
    input.current?.blur()
  })

  return (
    <div className="hidden md:flex flex-1 relative max-w-[370px] bg-slate-100 rounded-full px-2">
      <svg className="absolute pointer-events-none top-[50%] translate-y-[-50%]" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><path d="m0 0h24v24h-24z"/><path d="m15.5 14h-.79l-.28-.27c.98-1.14 1.57-2.62 1.57-4.23 0-3.59-2.91-6.5-6.5-6.5s-6.5 2.91-6.5 6.5 2.91 6.5 6.5 6.5c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99 1.49-1.49zm-6 0c-2.49 0-4.5-2.01-4.5-4.5s2.01-4.5 4.5-4.5 4.5 2.01 4.5 4.5-2.01 4.5-4.5 4.5z" fill="#334155" fillRule="nonzero"/></g></svg>
      <input
        ref={ref => input.current = ref}
        type="text"
        className="w-full bg-transparent border-0 px-8 outline-none py-2 text-[18px] text-slate-700 placeholder-slate-700 focus:placeholder-slate-500"
        placeholder="Kurse durchsuchen"
      />
      <span className="absolute right-[15px] top-[50%] translate-y-[-50%] text-[14px] text-slate-400 pointer-events-none">
          {isMac ? '[⌘ + K]' : '[Strg + K]'}
      </span>
    </div>
  )
}
