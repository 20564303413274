import React, {HTMLProps, ReactNode} from 'react'

interface ButtonProps extends HTMLProps<HTMLButtonElement> {
  type?: 'primary' | 'secondary'
}

export default function Button({
  children,
  type = 'primary',
}: ButtonProps) {
  let className = `inline-flex rounded-full px-4 py-2 transition-colors ${type === 'primary' ? 'bg-ibb hover:bg-ibb-accent text-white' : 'bg-white hover:bg-slate-100 text-ibb'}`

  return (
    <button className={className}>
      {children}
    </button>
  )
}
